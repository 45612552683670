<!-- @format -->
<script lang="ts">
    import { getStoresContext } from "~/stores";
    import type { StoresKey } from "~/stores";
    import PinkWave from "./common/PinkWave.svelte";
    import BodyContent from "./BodyContent.svelte";
    import PageIntro from "./home/PageIntro.svelte";


    export let request_store_key: StoresKey;
    export let fragment: Fragment;

    const { locale } = getStoresContext(request_store_key);
    const stores_context = getStoresContext(request_store_key);
    const { getFragmentConfig, getText } = stores_context.fieldUtils();

    let pinkWavePosition:any;
    let flipOrder:any;
    
    $: title = $locale ? getText("main_title", fragment) : "";
    $: subtitle = $locale ? getText("intro_text", fragment): "";
    $: pinkWavePosition = $locale ? getFragmentConfig("pinkWavePosition", fragment): 'none';
    $: flipOrder = $locale ? getFragmentConfig("flipOrder", fragment): false;

</script>

<PinkWave will_be={pinkWavePosition}>
    <BodyContent>
        <div class="intro">
            <PageIntro
            {title}
            introduction={subtitle}
            flip_order={flipOrder}
            />
        </div>
    </BodyContent>
</PinkWave>

<style>
    .intro {
        text-align: center;
    }
</style>